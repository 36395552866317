@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import "vars";


body {
    font-family: var(--font-primary);/*update this to site font*/
    font-size: 22px;
    color: var(--color-primary); /* update this to site body colour */
}
audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
    max-width: 100%;
}
img {
    height:auto !important;
}

.clear-float {
    clear:both;
}

.highlight {
    color:#bbbbbb;
    font-size:40px;
}

.button {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    padding: 10px 20px;
    max-width: 100%;
    color: #FFF;
    background-color: grey;
    border: 1px solid grey;
    box-sizing: border-box;
    transition: all 0.3s ease;
}
.button:hover {
    color: grey;
    background-color: transparent;
}

img.display-left {
    float:left;
    max-width: 50%;
    margin-right:20px;
    margin-bottom:10px;
}

img.display-right {
    float:right;
    max-width: 50%;
    margin-left:20px;
    margin-bottom:10px;
}
.two-col{
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
    @media screen and (max-width:768px) {
        grid-template-columns: 1fr;
    }
}
.three-col{
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
    @media screen and (max-width:768px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width:500px) {
        grid-template-columns: 1fr;
    }
}
.btn-solid{
    display: inline-block;
    background-color: var(--white);
    color: transparent;
    border-radius: 30px;
    padding:10px 2rem;
    text-decoration: none;
    transition: transform .3s ease-in-out;
    &:hover{
        transform: scale(1.2);
    }
}
.btn-solid-pink{
    @extend .btn-solid;
    color: var(--pink);
}
.btn-solid-blue{
    @extend .btn-solid;
    color: var(--blue);
}
.btn-outline{
    white-space: nowrap;
    background-color: transparent;
    border:3px solid var(--black);
    color:var(--black);
    border-radius: 30px;
    padding:10px 2rem;
    text-decoration: none;
    transition: all .3s ease-in-out;
    display:inline-block;
    width: 100%;
    max-width: 250px;
    text-align: center;
    &:hover{
        background-color: var(--black);
        color: var(--white);
    }
}
.btn-outline-pink{
    @extend .btn-outline;
    border-color: var(--pink);
    color: var(--pink);
    &:hover{
        background-color: var(--pink);
        color: var(--white);
    }
}

.btn-outline-blue{
    @extend .btn-outline;
    border-color: var(--blue);
    color: var(--blue);
    &:hover{
        background-color: var(--blue);
        color: var(--white);
    }
}

.btn-outline-yellow{
    @extend .btn-outline;
    border-color: var(--yellow);
    color: var(--yellow);
    &:hover{
        background-color: var(--yellow);
        color: var(--white);
    }
}
.btn-outline-white{
    @extend .btn-outline;
    border-color: var(--white);
    color: var(--white);
    &:hover{
        background-color: var(--white);
        color: var(--black);
    }
}

@media only screen and (max-width: 768px){
    img.display-left,
    img.display-right {
        float: none;
        max-width: 100%;
        margin: 20px 0;
    }
}
.heading-xl{
    font-size: clamp(22px, 5vw, 80px);
    font-weight: 700;
    line-height: clamp(21px, 4.9vw, 78px);
}
.heading-l{
    font-size: clamp(20px, 4vw, 60px);
    font-weight: 700;
}
.heading-m{
    font-size: clamp(18px, 3.5vw, 48px);
    font-weight: 700;
}
.heading-s{
    font-size: clamp(16px, 3vw, 22px);
    font-weight: 700;
}


.text-xl{
    font-size: clamp(20px, 3.5vw, 36px);
}
.text-l{
    font-size: clamp(18px, 3.25vw, 30px);
}
.text-m{
    font-size: clamp(16px, 3vw, 22px);
}

.text-pink{
    color:var(--pink);
}

.text-black{
    color:var(--black);
}
.text-white{
    color:var(--white);
}
.text-yellow{
    color:var(--yellow);
}
.text-blue{
    color:var(--blue);
}
.cke_editable{
    background-color: #cfcfcf;
}