:root {
    //color
    --black: #222121;
    --white: #ffffff;
    --grey: #707070;
    --pink: #EE337B;
    --blue: #4990CD;
    --yellow: #FAAF40;
    --pink-gradient: linear-gradient(165deg, rgba(238,51,123,1) 75%, rgba(250,175,64,1) 100%);

    //font style
    --font-primary: 'Roboto', sans-serif;

    //font color
    --color-primary: #222121;
    --color-secondary: #EE337B;
}